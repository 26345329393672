<template>
  <div
    class="list-table-row-item"
    :style="itemStyle"
    :title="titleText"
    v-if="!orderFlg"
    data-e2e="list-table-row-item"
  >
    <div class="item-text">{{ itemText }}</div>
  </div>
</template>

<script>
export default {
  name: 'ListTableRowItem',

  props: {
    itemKey: { type: String },
    itemText: { type: [Number, String, Object, Date], default: '' },
    itemStyle: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    }
  },

  computed: {
    titleText() {
      return this.itemText === '●' ? '' : this.itemText
    },
    orderFlg() {
      return this.itemKey === 'order'
    }
  }
}
</script>

<style lang="scss" scoped>
.list-table-row-item {
  display: flex;
  width: 100%;
  line-height: 55px;
  padding: 0 10px;
  font-size: 15px;
  box-sizing: border-box;
  text-align: center;
  > .item-text {
    flex: 1;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
